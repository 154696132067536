export default function LineBreak() {
  return (
    <hr
      style={{
        flexGrow: "1",
        marginLeft: ".5rem",
        borderWidth: ".1px",
      }}
    />
  );
}
