import Hero from "../components/Hero/Hero";
import Footer from "../components/Navigation/Footer";
export default function HomePage() {
  return (
    <>
      <Hero />
      <Footer />
    </>
  );
}
