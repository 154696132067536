import Languages from "./Languages";
import Technologies from "./Technologies";

export default function Skills() {
  return (
    <>
      <Languages />
      <Technologies />
    </>
  );
}
